<template>
  <div class="">
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-8 mb-4">
            <div class="form-row mt-4">
              <div class="col-auto">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_order"  input-class="form-control" placeholder="วันที่สั่งซื้อ" :append-to-body="false" :popup-style="{ left: 0 }"></date-picker>
              </div>
              <div class="col-auto">
                <date-picker :format="'DD/MM/YYYY'" v-model="criteria.date_refund"  input-class="form-control" placeholder="วันที่คืนเงิน" :append-to-body="false" :popup-style="{ left: 0 }"></date-picker>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <b-input-group>
              <b-input-group-append>
                <b-input-group-text class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input
                placeholder="ระบุการค้นหา เช่น รหัสลูกค้า ชื่อลูกค้า สถานะการสั่งซื้อ สถานะการจ่าย"
                v-model="criteria.search"
                class="form-control-group"
                @keyup.enter.native="searchHandler()"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button size="md" text="Button" variant="primary" @click="searchHandler()" class="btn_border_radius_right_top_bt" style="width: 86px;" > ค้นหา </b-button>
              </b-input-group-append>
            </b-input-group>
            <br>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th>วันที่กดโอนคืน</th>
                  <th>วันที่สั่งซื้อ</th>
                  <th>ใบสั่งซื้อ</th>
                  <th>ลูกค้า</th>
                  <th>ร้านค้า</th>
                  <th>สถานะการคืนเงิน</th>
                  <th>สาเหตุโอนเงินคืน</th>

                  <th>ชื่อผู้โอน</th>
                  <th>ธนาคาร</th>
                  <th>วันที่</th>
                  <th>เวลา</th>
                  <th>ยอดโอนเข้า</th>

                  <th>ยอดโอนคืน</th>
                  <th>วันที่โอนคืน</th>
                  <th>ธนาคาร</th>
                  <th>เลขบัญชี</th>
                  <th>ชื่อบัญชี</th>
                  <th>หมายเหตุ</th>
                  
                  <th>สถานะการตรวจสอบ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data) in items" :key="`list_Refund_${data.id}`">
                  <td>{{date_format(data.created,"DD/MM/YYYY")}}</td>
                  <td>{{date_format(data.date_order,"DD/MM/YYYY")}}</td>
                  <td>{{data.order_code}}</td>
                  <td>{{data.customer_name}}</td>
                  <td>{{data.store_name}}</td>
                  <td>{{get_refund_name_by_status(data.status_refund)}}</td>
                  <td>{{data.issue_type}}</td>

                  <td>{{data.bank_account}}</td>
                  <td>{{data.payment_methods}}</td>
                  <td>{{date_format(data.payment,"DD/MM/YYYY")}}</td>
                  <td>{{date_format(data.payment,"HH:mm")}}</td>
                  <td>{{data.payment_amount | number-decimal-format}}</td>

                  <td>{{data.amount | number-decimal-format}}</td>
                  <td>{{date_format(data.refund,"DD/MM/YYYY")}}</td>
                  <td>{{data.customer_bank}}</td>
                  <td>{{data.customer_bank_number}}</td>
                  <td>{{data.customer_bank_name}}</td>
                  <td>{{data.remark}}</td>
                  <td>{{get_issue_name_by_status(data.issue_status)}}</td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-4 mt-4 custom-card">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-6">
              <h3>สถานะปัญหา</h3>
              <i class="fas fa-exclamation-circle text-warning"></i> 1 "เกิดปัญหาขึ้น แล้วแจ้งเป็น Refund ขึ้นมา"<br>
              <i class="fas fa-check text-primary"></i> 2 "เมื่อแก้ไขปัญหานั้น ๆ แล้ว"<br>
              <i class="fas fa-check-circle text-success"></i> 3 "ผ่านการตรวจสอบ"<br>
              <i class="fas fa-times-circle text-danger"></i> 4 "ไม่ผ่านการตรวจสอบ"<br>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
          <select class="form-control mb-2" id="pageLimit" style="width: 80px;" v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages" :key="data" :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ </strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.pageTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label"
                      style="font-size: 12px;
                      font-family: Sarabun-Light;">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from '../../../util/config';
import Constants from '../../../util/constants';
import DateUtils from "../../../util/dateUtils";
  export default {
    name: 'page-refund',
    data() {
      return {
        permission:{
          appSlug: 'refund',
          actionData: {}
        },
        criteria: {
          date_order: "",
          date_refund: "",
          search: "",
          orderBy: "",
          ascDesc: ""
        },
        item: {},
        items: [],
        paging: {
          page: 1,
          limit: Config.pagination.limit,
          pages: Config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        },
        RefundTypes: [],
        statusTypes: Constants.refund_status
      };
    },
    methods: {
      onEditHandler(id){
        this.SessionStorageUtils.setSession("page-refund", id);
        this.$router.push(`/refund/edit/${id}`);
      },
      onEditHandlerResolve (id) {
        this.SessionStorageUtils.setSession("page-refund", id);
        this.$router.push(`/refund/resolve/${id}`);
      },
      onApproveHandler (data) {
        this.item = JSON.parse(JSON.stringify(data));
      },

      /******* local function ******/
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        let date_order = "";
        let date_refund = "";
        if (this.criteria.date_order)date_order = DateUtils.dateFormat(this.criteria.date_order,"YYYY-MM-DD");       
        if (this.criteria.date_refund)date_refund = DateUtils.dateFormat(this.criteria.date_refund,"YYYY-MM-DD");       

        let params = `search=${this.criteria.search}`;
        params += `&date_order=${date_order}`;
        params += `&date_refund=${date_refund}`;
        params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_refund?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.rowTotal = data.rowTotal;
          this.paging.pageTotal = total;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      checkDisabledDelete(){
        let resp = true;
        for(const v of this.items){
          if(v.selected){
            resp = false;
            break;
          }
        }
        return resp;
      },
      async initialData(){
        await this.getListItems();
      },
      get_refund_name_by_status (status) {
        const result = Constants.refund_status.find(v=>v.id==status);
        return result ? result.text : '';
      },
      get_issue_name_by_status (status) {
        const result = Constants.issue_status.find(v=>v.id==status);
        return result ? result.text : '';
      },
      getRefundTypeName (type) {
        return type
       // const result = this.RefundTypes.find(v=>v.id==type);
//return result ? result.text : '';
      },
      date_format(date,format){
        return DateUtils.date_format_tz(date,format);
      }
      /******* local function ******/
    },
    async mounted() {
      //await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      //this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      await this.initialData();
      //this.SessionStorageUtils.deleteSession("page-refund");
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
